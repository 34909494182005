import React from "react"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.en"

const IndexEN = ({ location }) => {
  return (
    <>
      <Metatags
        title="Multilógica | Privacy Policy"
        description="Mattress machinery supplier"
        url="https://newsite.multilogica.ind.br/en/privacy-policy"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="mattress, sewing machine, mammut, beckmann sew"
        language="en"
      />
      <NavBar location={location["pathname"]} />
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mx-2">
          <div className="col-span-2" />
          <div className="col-span-8 text-center">
            <div className="text-5xl font-semibold text-mi-blue">
              Privacy Policy
            </div>
            <div className=" h-8" />
            <div className=" text-left text-xl">
              <p className=" font-bold">Init</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                PRIVACY POLICY – Multilógica Comércio de Materiais Elétricos e
                Serviços Ltda ME
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica Comércio de Materiais Elétricos e Serviços Ltda ME,
                a company registered under CNPJ/MF nº 64.880.743/0001-86,
                hereinafter referred to as Multilógica, values the privacy of
                its users, customers, suppliers, and employees, and has
                therefore created this Privacy Policy in accordance with the
                General Data Protection Law nº 13.709/2018.
              </p>
              <p>&nbsp;</p>
              <p>
                When you register or subscribe to Multilógica's newsletter, you
                provide us with some personal data to enable your purchase.
                Multilógica aims to demonstrate its commitment to security,
                privacy, transparency, and the handling of personal data, and
                has dedicated this document to explain to the data subject which
                data is collected, how it is collected, the treatment given, how
                it is stored, whether there is any sharing with third parties,
                among other essential information regarding personal data. The
                user declares that they have read this Privacy Policy and
                Personal Data Processing Policy in full, being fully aware of
                it, and expressly and unequivocally agreeing with the terms
                stipulated herein, authorizing the collection of the personal
                data and information mentioned herein, as well as their use for
                the purposes specified below. If you do not agree with these
                directives, the user should discontinue their access to
                Multilógica's website.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">INDEX 1.</p>
              <p>&nbsp;</p>
              <p>GENERAL INFORMATION AND DEFINITIONS</p>
              <p>2. DATA COLLECTION AND PROCESSING</p>
              <p>3. DATA STORAGE</p>
              <p>4. DATA SHARING</p>
              <p>5. LEGAL HYPOTHESES FOR DATA DISCLOSURE</p>
              <p>6. DATA RETENTION</p>
              <p>7. DATA SUBJECT RIGHTS</p>
              <p>8. COOKIES</p>
              <p>9. POLICY REVISIONS</p>
              <p>10. CONTACT</p>
              <p>11. JURISDICTION AND LEGISLATION</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">
                1. GENERAL INFORMATION AND DEFINITIONS
              </p>
              <p>&nbsp;</p>
              <p>
                User: all individuals who will use or visit the website, over 18
                (eighteen) years old or emancipated and fully capable of
                performing civil acts or those absolutely or relatively
                incapable duly represented or assisted.
              </p>
              <p>&nbsp;</p>
              <p>
                Personal Data: means any information provided and/or collected
                by Multilógica, by any means, even if public, that: (I)
                identifies, or that, when used in combination with other
                information processed by Multilógica, identifies an individual;
                or (II) through which the identification or contact information
                of an individual can be derived. Personal Data can be in any
                media or format, including electronic or computerized records,
                as well as paper-based files. However, Personal Data does not
                include business phone, business cell phone number, business
                address, business email.
              </p>
              <p>&nbsp;</p>
              <p>
                Purpose: the objective, the purpose that Multilógica wishes to
                achieve from each act of processing personal information.
              </p>
              <p>&nbsp;</p>
              <p>
                Necessity: justification for which it is strictly necessary to
                collect personal data, to achieve the purpose, avoiding
                excessive collection.
              </p>
              <p>&nbsp;</p>
              <p>
                Legal bases: legal foundation that makes the processing of
                personal data legitimate for a specific prior purpose by
                Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Consent: express and unequivocal authorization given by the
                User, the personal data subject, for Multilógica to process
                their personal data for a previously described purpose, where
                the necessary legal basis for the act requires the express
                authorization of the subject.
              </p>
              <p>&nbsp;</p>
              <p>
                By accessing and/or using Multilógica's website, the User
                declares to be at least 18 (eighteen) years old and to have full
                and express capacity to accept the terms and conditions of this
                Privacy Policy and the Consent Term for all legal purposes.
              </p>
              <p>&nbsp;</p>
              <p>
                If the User does not fit the description above and/or does not
                agree, even in part, with the terms and conditions contained in
                this Privacy Policy, they should not access and/or use any
                services offered by Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">2. DATA COLLECTION AND PROCESSING</p>
              <p>&nbsp;</p>
              <p>
                The User is aware that they provide information consciously and
                voluntarily through registration forms, or through the website
                operated by Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                When the User registers and/or fills out forms offered by
                Multilógica, including on the website operated by it, certain
                requested Personal Data will be kept confidential and will be
                used only for the purposes defined below:
              </p>
              <p>&nbsp;</p>
              <p>
                Data collected: Personal Name, Corporate Name, email, phone, and
                company name (CLIENTS and WEBSITE USERS) Purpose of data
                use/processing: To access informational content about taxation,
                events, promotions, and relevant news to Multilógica, the User
                will need to provide their Name, Email, phone, and the Company
                they work for. Multilógica will send marketing emails to users
                who provide this information. The collected Personal Data will
                not be used to send any type of SPAM or for any purpose other
                than what is stated here. Legal Basis: Consent Consent: I agree
                to provide my Name, Email, and the Company I work for to access
                the material available on the website, as well as to receive
                Multilógica's Newsletter and other marketing emails. I am aware
                that the data provided will be used internally for promotional
                purposes by Multilógica and I agree to receive emails from
                Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Data collected: Name/Corporate Name, phone, ZIP code, and email
                (Customer Service) Purpose of data use/processing: If the data
                subject wants to contact the company by any means (phone,
                in-person, or via website) to request information. Legal Basis:
                Consent Consent: I agree to provide my name and email for
                Multilógica to return my contact/request for information.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">3. DATA STORAGE</p>
              <p>&nbsp;</p>
              <p>
                Multilógica will store the collected information on its own
                servers or on cloud servers contracted by its service providers.
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica uses reasonable market and legally required means to
                preserve the privacy of the collected data. Thus, it adopts, for
                example, the following precautions:
              </p>
              <p>&nbsp;</p>
              <p>
                When necessary, Multilógica uses standard and market methods to
                encrypt and anonymize the collected data; Multilógica has
                protection against unauthorized access to its systems;
                Multilógica only authorizes access to previously established
                persons to the location where the collected information is
                stored; Those who come into contact with the information commit
                to maintaining absolute confidentiality. Breach of
                confidentiality will result in civil liability and the
                responsible party will be held accountable under Brazilian law;
                Multilógica makes its best efforts to preserve the privacy of
                Users' data. However, no site is completely secure and
                Multilógica cannot guarantee that all information that travels
                on the Pages will not be subject to unauthorized access
                perpetrated by methods developed to obtain information
                improperly.
              </p>
              <p>&nbsp;</p>
              <p>
                For this reason, we encourage Users to take appropriate measures
                to protect themselves, such as, for example, keeping all created
                usernames and passwords confidential.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">4. DATA SHARING</p>
              <p>&nbsp;</p>
              <p>
                Multilógica may disclose the collected personal data to third
                parties in the following situations and within the limits
                authorized by law: For administrative purposes such as:
                research, planning, service development, security, issuance of
                invoices, banking, payment platform, and risk management and
                other business or administrative partners; With business
                partners to enable the delivery of products purchased from
                Multilógica; When necessary to comply with a legal obligation,
                determination of a competent authority, or court decision.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">
                5. LEGAL HYPOTHESES FOR DATA DISCLOSURE
              </p>
              <p>&nbsp;</p>
              <p>
                In some cases, Multilógica may disclose the collected Personal
                Data to comply with applicable law or by court or administrative
                order or subpoena.
              </p>
              <p>&nbsp;</p>
              <p>Legal disclosure may be made to:</p>
              <p>&nbsp;</p>
              <p>
                Comply with the law; Investigate, prevent, or take action
                related to cooperation with public authorities or to protect
                national security; Contract execution; Investigation and defense
                of third-party claims; Protection of the security or integrity
                of the services;
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">6. DATA RETENTION</p>
              <p>&nbsp;</p>
              <p>
                Multilógica will retain data subjects' information only for the
                period necessary for the purposes for which that information was
                processed, including the security of processing, with legal and
                regulatory obligations (e.g., auditing, accounting, and
                statutory retention terms), litigation regarding processing, and
                for the establishment, exercise, or defense of legal claims.
              </p>
              <p>&nbsp;</p>
              <p>
                The data subject may request the deletion of data through a
                direct request to the company via email:
                contato@multilogica.ind.br or phone (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">7. DATA SUBJECT RIGHTS</p>
              <p>&nbsp;</p>
              <p>
                In compliance with applicable regulations regarding the
                processing of personal data, Multilógica respects and guarantees
                the User the possibility of submitting requests based on the
                following rights:
              </p>
              <p>&nbsp;</p>
              <p>
                confirmation of the existence of processing; access to data;
                correction of incomplete, inaccurate, or outdated data;
                anonymization, blocking, or deletion of unnecessary, excessive,
                or non-compliant data; deletion of data processed with the
                User's consent; obtaining information about public or private
                entities with which Multilógica has shared their data;
                information about the possibility of not providing consent, as
                well as being informed about the consequences, in case of
                refusal; revocation of consent. Multilógica is available to
                fulfill data subjects' rights requests through a request from
                the data subject to be sent via email to
                contato@multilogica.ind.br or phone (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica will make every effort to meet such requests as soon
                as possible.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">8. POLICY REVISIONS</p>
              <p>&nbsp;</p>
              <p>
                If Multilógica modifies this policy, such changes will be
                published visibly on Multilógica's website, and whenever
                possible, the data subject will be informed.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">9. COOKIES</p>
              <p>&nbsp;</p>
              <p>
                Cookies are files or information that can be stored on your
                devices when you visit Multilógica's Pages. Generally, a cookie
                contains the name of the site it came from, its lifetime, and a
                value, which is randomly generated.
              </p>
              <p>&nbsp;</p>
              <p>
                Multilógica uses cookies to facilitate the use and better adapt
                the Pages to your interests and needs, as well as to compile
                information about the use of our site, helping to improve its
                structures and content. Cookies can also be used to speed up
                your activities and future experiences on the Pages.
              </p>
              <p>&nbsp;</p>
              <p>
                Types of cookies: Necessary What do they do? These cookies help
                us understand how visitors interact with Multilógica's Pages,
                providing information about the areas visited, the time spent on
                the site, and any problems encountered, such as error messages.
              </p>
              <p>&nbsp;</p>
              <p>
                Types of cookies: Functional What do they do? These cookies
                allow Multilógica's Pages to remember your choices, to provide a
                more personalized experience.
              </p>
              <p>&nbsp;</p>
              <p>
                Types of cookies: Marketing What do they do? These cookies are
                used to provide more relevant content and of interest to Users.
                They can be used to present more targeted advertising to the
                user. They also allow the measurement of the effectiveness of a
                Multilógica advertising campaign. Additionally, these cookies
                can be used to indicate to Multilógica's Pages the sites that
                the User has visited.
              </p>
              <p>&nbsp;</p>
              <p>
                After the User consents to the use of cookies when using
                Multilógica's Pages, it will store a cookie on their device to
                remember this in the next session.
              </p>
              <p>&nbsp;</p>
              <p>
                At any time, the User can revoke their consent regarding
                cookies, for which they should delete the cookies from
                Multilógica's Pages using their preferred browser settings. For
                more information on how to proceed with cookie management in
                browsers:
              </p>
              <p>&nbsp;</p>
              <p>Internet Explorer:</p>
              <p>
                <a
                  href="https://support.microsoft.com/pt-br/help/17442/windows-internetexplorer-delete-manage-cookies"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://support.microsoft.com/
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Mozilla Firefox:</p>
              <p>
                <a
                  href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-queos-sites-usam"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://support.mozilla.org/
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Google Chrome:</p>
              <p>
                <a
                  href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://support.google.com/
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Safari:</p>
              <a
                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                rel="noopener noreferrer"
                target="_blank"
              >
                <p>https://support.apple.com/</p>
              </a>
              <p>&nbsp;</p>
              <p>
                Finally, we remind you that if the User does not accept some
                cookies from Multilógica's Pages, certain services may not
                function ideally.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">10. CONTACT</p>
              <p>&nbsp;</p>
              <p>
                Multilógica is available to resolve any doubts or requests from
                data subjects via email: contato@multilogica.ind.br or by phone
                at (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">11. JURISDICTION AND LEGISLATION</p>
              <p>&nbsp;</p>
              <p>
                This Policy will be governed, interpreted, and executed in
                accordance with the Laws of the Federative Republic of Brazil,
                especially Law nº 13.709/2018, regardless of the laws of other
                states or countries, with the competent court of Santo André,
                São Paulo to resolve any doubts arising from this document.
              </p>
              <p>&nbsp;</p>
              <p>Last update: November 10, 2021.</p>
              <p>&nbsp;</p>
              <p>
                Multilógica Comércio de Materiais Elétricos e Serviços Ltda. -
                ME
              </p>
            </div>
          </div>
        </div>
        <div className="h-10" />
      </div>
      <div className="h-20" />
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default IndexEN
